<template>

  <div>
    <!-- Filters -->
    <posts-list-filters
      :category-filter.sync="categoryFilter"
      :category-options="categoryOptions"
      :is-active-filter.sync="isActiveFilter"
      :is-active-options="isActiveOptions"
      :range-date.sync="rangeDate"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('post:create')"
                :to="{ name: 'add-post' }"
                variant="primary"
                @click="isAddNewTagsSidebarActive = true"
              >
                <span class="text-nowrap">Add post</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refTagsTable"
          class="position-relative"
          :items="fetchPosts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: preview -->
          <template #cell(preview)="data">
            <b-link
              :to="{ name: 'edit-post', params: { id: data.item.id } }"
            >
              <b-img
                height="60"
                width="100"
                :src="VALID_IMAGE + data.item.image.key"
              />
            </b-link>

          </template>
          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'edit-post', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.title }}
              </b-link>
            </b-media>
          </template>
          <!-- Column: isActive -->
          <template #cell(isActive)="data">
            <span class="text-capitalize">
              <b-form-checkbox
                :checked="data.item.isActive"
                switch
                @change="switchToggle(data.item)"
              />
            </span>
          </template>
          <!-- Column: CreatedAt -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.createdAt) }}
            </span>
          </template>
          <!-- Column: CreatedAt -->
          <template #cell(updated_at)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.updatedAt) }}
            </span>
          </template>
          <!-- Column: categoryId -->
          <template #cell(categoryId)="data">
            <span
              v-if="categoryOptions"
              class="text-capitalize"
            >
              {{ mapCategoryPost(data.item.categoryId) }}
            </span>
          </template>
          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveStatusProduct(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status }}
            </b-badge>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              v-if="$can('post:update')"
              :to="{ name: 'edit-post', params: { id: data.item.id } }"
            >
              Edit
            </b-link>
            <b-link
              v-if="$can('post:delete')"
              @click="deletePost(data.item.id)"
            >
              Delete
            </b-link>
          </template>

        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPosts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BPagination, BOverlay, BBadge, BFormCheckbox, BImg
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import usePostsList from '@/views/postManager/posts'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import PostsListFilters from '@/components/postManager/PostsListFilters.vue'
import { VALID_IMAGE } from '@/utils/config'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
    PostsListFilters,
    BBadge,
    BFormCheckbox,
    BImg,
  },
  computed: {
    ...mapGetters('posts', ['isActiveOptions']),
  },
  methods: {
    mapCategoryPost(data) {
      if (this.categoryOptions.find(x => x.value === data)) {
        return this.categoryOptions.find(x => x.value === data).label
      }
      return 'None'
    },
    switchToggle(post) {
      const payload = {}
      payload.isActive = !post.isActive
      store.dispatch('posts/updatePost',
        { id: post.id, post: payload })
        .then(() => {
          this.refreshTable()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update active success',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error update switch',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    switchIsShow(tag) {
      const payload = tag
      // eslint-disable-next-line prefer-destructuring
      payload.is_show = !tag.is_show
      store.dispatch('product/updateTags',
        { id: tag.id, tag: payload })
        .then(() => {
          this.refreshTable()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update show success',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error update show',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    showEditBar(data) {
      this.dataTag = {
        name: data.name,
        is_show: {
          label: this.statusOptions.find(e => e.value === data.is_show).label,
          value: data.is_show,
        },
        id: data.id,
      }
      this.isEditTagsSidebarActive = true
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    deletePost(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this post.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('posts/deletePost', { id }).then(() => {
              this.refreshTable()
            })
          }
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
    },

  },

  setup() {
    const dataTag = ref({})
    const isAddNewTagsSidebarActive = ref(false)
    const isEditTagsSidebarActive = ref(false)
    const {
      timeout,
      showTable,
      fetchPosts,
      tableColumns,
      perPage,
      currentPage,
      totalPosts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTagsTable,
      refetchData,
      categoryFilter,
      categoryOptions,
      rangeDate,
      resolveStatusProduct,
      isActiveFilter,
    } = usePostsList()
    return {
      timeout,
      showTable,
      // Sidebar
      dataTag,
      isEditTagsSidebarActive,
      isAddNewTagsSidebarActive,
      fetchPosts,
      tableColumns,
      perPage,
      currentPage,
      totalPosts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTagsTable,
      refetchData,
      categoryFilter,
      categoryOptions,
      rangeDate,
      resolveStatusProduct,
      // Filter
      avatarText,
      isActiveFilter,
      VALID_IMAGE,
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
