import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import * as config from '@/utils/config'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePostsList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refTagsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'preview', sortable: false },
    { key: 'title', sortable: false },
    { key: 'categoryId', label: 'Category', sortable: false },
    { key: 'isActive', label: 'Active', sortable: false },
    { key: 'created_at', sortable: false },
    { key: 'updated_at', sortable: false },
    // { key: 'status', sortable: false },
    { key: 'action' },
  ]
  const perPage = ref(10)
  const totalPosts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const categoryFilter = ref(null)
  const isActiveFilter = ref(null)
  const categoryOptions = ref([])
  const rangeDate = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refTagsTable.value
      ? refTagsTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPosts.value,
    }
  })

  const refetchData = () => {
    refTagsTable.value.refresh()
  }
  const fetchPosts = (ctx, callback) => {
    const fromDate = ref(null)
    const toDate = ref(null)
    if (rangeDate.value.includes('to')) {
      fromDate.value = rangeDate.value.split('to')[0]
      toDate.value = rangeDate.value.split('to')[1]
    } else {
      fromDate.value = rangeDate.value
    }
    store
      .dispatch('posts/fetchPosts', {
        q: searchQuery.value === '' ? null : searchQuery.value,
        take: perPage.value,
        isActive: isActiveFilter.value,
        page: currentPage.value,
        categoryId: categoryFilter.value,
        from: fromDate.value ? new Date(fromDate.value) : null,
        to: toDate.value ? new Date(toDate.value) : null,
      })
      .then(response => {
        console.log(response)
        const categories = response.body.data
        totalPosts.value = response.body.meta.itemCount
        callback(categories)
      })
      .catch(e => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching posts list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchPostCategories = (ctx, callback) => {
    store.dispatch('postCategory/fetchPostCategories', {
      take: 50,
      page: 1,
    }).then(res => {
      console.log(res)
      categoryOptions.value = res.body.data.map(x => ({
        label: x.name,
        value: x.id,
      }))
    }).catch(e => {
      console.log(e)
      toast({
        component: ToastificationContent,
        props: {
          title: e.response.data.error,
          icon: 'BellIcon',
          variant: 'danger',
        },
      })
    })
  }
  fetchPostCategories()
  const timeout = ref(null)
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      categoryFilter,
      isActiveFilter,
      rangeDate,
    ],
    () => {
      showTable.value = true
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 600)
    },
  )
  const resolveStatusProduct = status => {
    if (status === 'draft') return 'info'
    if (status === 'pending') return 'warning'
    if (status === 'approved') return 'success'
    if (status === 'cancel') return 'danger'
    return 'info'
  }
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchPosts,
    tableColumns,
    perPage,
    currentPage,
    totalPosts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTagsTable,
    refetchData,
    isActiveFilter,
    // Extra
    categoryFilter,
    categoryOptions,
    rangeDate,
    resolveStatusProduct,
  }
}
